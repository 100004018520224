<template>
    <a href="#"
       @click.prevent="$root.$children[0].openModal('new-workflow-modal', {id:row.item.workflow_id}, refreshTable)">
        {{ row.item.workflow_name }}
    </a>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>